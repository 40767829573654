import { useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { Col, Container, Row } from 'react-bootstrap'
import "./leadership.scss"


const Leadership = ({ className }) => {
    const { t } = useTranslation("about", { keyPrefix: "Leadership" })
    const [switchTooggledRosalba, setSwitchToggled1] = React.useState(false);  //TODO: Refactor to use a single state
    const [switchTooggledRemi, setSwitchToggled2] = React.useState(false);
    const [switchTooggledVram, setSwitchToggled3] = React.useState(false);
    const [switchTooggledCatherine, setSwitchToggled6] = React.useState(false);
    const [switchTooggledDavid, setSwitchToggled7] = React.useState(false);
    const [switchTooggledJulia, setSwitchToggled8] = React.useState(false);
    const [switchTooggledTeams, setSwitchToggled9] = React.useState(true);
    const [switchTooggledNico, setSwitchToggled11] = React.useState(false);  //TODO: Refactor to use a single state


    const ToggleSwitchRosalba = () => {
        switchTooggledRosalba ? setSwitchToggled1(false) : setSwitchToggled1(true);
    }

    const ToggleSwitchRemi = () => {
        switchTooggledRemi ? setSwitchToggled2(false) : setSwitchToggled2(true);

    }

    const ToggleSwitchVram = () => {
        switchTooggledVram ? setSwitchToggled3(false) : setSwitchToggled3(true);

    }

    const ToggleSwitchNico = () => {
        switchTooggledNico ? setSwitchToggled11(false) : setSwitchToggled11(true);
    }

    const ToggleSwitchCatherine = () => {
        switchTooggledCatherine ? setSwitchToggled6(false) : setSwitchToggled6(true);
    }

    const ToggleSwitchDavid = () => {
        switchTooggledDavid ? setSwitchToggled7(false) : setSwitchToggled7(true);

    }

    const ToggleSwitchJulia = () => {
        switchTooggledJulia ? setSwitchToggled8(false) : setSwitchToggled8(true);
    }

    const ToggleSwitchTeams = (e) => {
        e.target.id === "boardTeam" ? setSwitchToggled9(false) : setSwitchToggled9(true);
    }



    return (
        <section id="leadership" className={(className ? className + " " : "") + "leadership"}>
            <Container fluid>
                <div className="max-container-big">
                    <h6 className="header-square blue md-large lg-large">{t("Leadership Team")}</h6>
                    <Row className="">
                        <Row className="teams">
                            <Col className={"manage bottom-line " + (switchTooggledTeams ? " teams_active" : " ")} xl={4} lg={4} md={6} sm={6} xs={6}>
                                <div className="max-container-teams">
                                    <span id="manageTeam" onClick={(e) => ToggleSwitchTeams(e)} aria-hidden="true"> {t("Leadership Team")}</span>
                                </div>
                            </Col >
                            <Col className={"board bottom-line " + (switchTooggledTeams ? " " : "teams_active")} xl={4} lg={4} md={6} sm={6} xs={6}>
                                <div className="max-container-teams">
                                    <span id="boardTeam" onClick={(e) => ToggleSwitchTeams(e)} aria-hidden="true"> {t("Board of directors")}</span>
                                </div>
                            </Col>
                            <Col className='bottom-line empty-line' xl={4} lg={4} xs={1}></Col>
                        </Row>
                        <Row className={"team_management " + (switchTooggledTeams ? " display_teams" : " no-display_teams")} >
                            <Col className="" xl={4} lg={4} md={6} sm={6} xs={6}>
                                <div className="max-container">
                                    <Col className="img-leadership rosalba-lg" xl={12} lg={12} md={12} sm={12} xs={12}></Col>
                                    <Col className="text-leader-container" xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <div className="name-leader">Rosalba Reynoso</div>
                                        <div className="title-leader">CEO</div>
                                        <a className="leader-linkedin" href="https://www.linkedin.com/in/rosalba-reynoso-a1969896/" target="_blank" rel="noreferrer"><span aria-label="linkedin"></span></a>
                                        <div className={(switchTooggledRosalba ? "text-leader-full" : "text-leader")}>{t("Rosalba has over 20 years of experience in managing companies in the service industry")}</div>
                                        <span className="readmore-leader" onClick={ToggleSwitchRosalba} aria-hidden="true"> {t("Read")} {(switchTooggledRosalba ? t("less") : t("more"))}</span>
                                    </Col>
                                </div>
                            </Col>
                            <Col className="" xl={4} lg={4} md={6} sm={6} xs={6}>
                                <div className="max-container">
                                    <Col className="img-leadership remi-lg" xl={12} lg={12} md={12} sm={12} xs={12}></Col>
                                    <Col className="text-leader-container" xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <div className="name-leader">Remi Vespa</div>
                                        <div className="title-leader">{t("President")}</div>
                                        <a className="leader-linkedin" href="https://www.linkedin.com/in/remivespa/" target="_blank" rel="noreferrer"><span aria-label="linkedin"></span></a>
                                        <div className={(switchTooggledRemi ? "text-leader-full" : "text-leader")}>{t("A veteran of the software industry, Remi has gained his experience in the main geographies")}</div>
                                        <span className="readmore-leader" onClick={ToggleSwitchRemi} aria-hidden="true"> {t("Read")} {(switchTooggledRemi ? t("less") : t("more"))}</span>
                                    </Col>
                                </div>
                            </Col>
                            <Col className="" xl={4} lg={4} md={6} sm={6} xs={6}>
                                <div className="max-container">
                                    <Col className="img-leadership vram" xl={12} lg={12} md={12} sm={12} xs={12}></Col>
                                    <Col className="text-leader-container" xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <div className="name-leader">Vram Kouramajian</div>
                                        <div className="title-leader">{t("Chief Mentor")}</div>
                                        <a className="leader-linkedin" href="https://www.linkedin.com/in/vramkouramajian/" target="_blank" rel="noreferrer"><span aria-label="linkedin"></span></a>
                                        <div className={(switchTooggledVram ? "text-leader-full" : "text-leader")}>{t("Dr. Vram Kouramajian brings more than twenty-five years of experience in the design and delivery of large-scale mission critical systems")}</div>
                                        <span className="readmore-leader" onClick={ToggleSwitchVram} aria-hidden="true"> {t("Read")} {(switchTooggledVram ? t("less") : t("more"))}</span>
                                    </Col>
                                </div>
                            </Col>
                            {/* <Col className="" xl={4} lg={4} md={6} sm={6} xs={6}>
                                <div className="max-container">
                                    <Col className="img-leadership philippe" xl={12} lg={12} md={12} sm={12} xs={12}></Col>
                                    <Col className="text-leader-container" xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <div className="name-leader">Philippe Le Berre</div>
                                        <div className="title-leader">{t("Chief Security Officer")}</div>
                                        <a className="leader-linkedin" href="https://www.linkedin.com/in/philippe-le-berre-944a3714a/" target="_blank"><span aria-label="linkedin"></span></a>
                                        <div className={(switchTooggledPhilippe ? "text-leader-full" : "text-leader")}>{t("Philippe brings superior skills in cybersecurity with experience in multiple fields")}</div>
                                        <span className="readmore-leader" onClick={ToggleSwitchPhilippe} aria-hidden="true"> {t("Read")} {(switchTooggledPhilippe ? t("less") : t("more"))}</span>
                                    </Col>
                                </div>
                            </Col> */}
                          
                            <Col className="" xl={4} lg={4} md={6} sm={6} xs={6}>
                                <div className="max-container">
                                    <Col className="img-leadership nico" xl={12} lg={12} md={12} sm={12} xs={12}></Col>
                                    <Col className="text-leader-container" xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <div className="name-leader">Nicolas Arigon</div>
                                        <div className="title-leader">{t("Director, Special Projects")}</div>
                                        <a className="leader-linkedin" href="https://www.linkedin.com/in/nicol%C3%A1s-arig%C3%B3n-perdomo-7377b125" target="_blank"><span aria-label="linkedin"></span></a>
                                        <div className={(switchTooggledNico ? "text-leader-full" : "text-leader")}>{t("Nicolas Arigon has been a part of Blue Trail since its inception")}</div>
                                        <span className="readmore-leader" onClick={ToggleSwitchNico} aria-hidden="true"> {t("Read")} {(switchTooggledNico ? t("less") : t("more"))}</span>
                                    </Col>
                                </div>
                            </Col>
                        </Row>

                        <Row className={"team_directors " + (switchTooggledTeams ? " no-display_teams" : " display_teams")} >
                            <Col className="" xl={4} lg={4} md={6} sm={6} xs={6}>
                                <div className="max-container">
                                    <Col className="img-leadership catherine" xl={12} lg={12} md={12} sm={12} xs={12}></Col>
                                    <Col className="text-leader-container" xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <div className="name-leader">Catherine Lewi</div>
                                        <div className="title-leader">{t("Board Member")}</div>
                                        <a className="leader-linkedin" href="https://www.linkedin.com/in/catherinelewi/" target="_blank" rel="noreferrer"><span aria-label="linkedin"></span></a>
                                        <div className={(switchTooggledCatherine ? "text-leader-full" : "text-leader")}>{t("Ms Lewi is a former executive in charge of global marketing")}</div>
                                        <span className="readmore-leader" onClick={ToggleSwitchCatherine} aria-hidden="true"> {t("Read")} {(switchTooggledCatherine ? t("less") : t("more"))}</span>
                                    </Col>
                                </div>
                            </Col>
                            <Col className="" xl={4} lg={4} md={6} sm={6} xs={6}>
                                <div className="max-container">

                                    <Col className="img-leadership david" xl={12} lg={12} md={12} sm={12} xs={12}></Col>
                                    <Col className="text-leader-container" xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <div className="name-leader">David Ziegler</div>
                                        <div className="title-leader">{t("Board Member")}</div>
                                            <a className="leader-linkedin" href="http://linkedin.com/in/david-s-ziegler-bb255684" target="_blank" rel="noreferrer"><span aria-label="linkedin"></span></a>
                                        <div className={(switchTooggledDavid ? "text-leader-full" : "text-leader")}>{t("A former President and CEO at ACE Hardware")} </div>
                                        <span className="readmore-leader" onClick={ToggleSwitchDavid} aria-hidden="true"> {t("Read")} {(switchTooggledDavid ? t("less") : t("more"))}</span>
                                    </Col>

                                </div>
                            </Col>
                            <Col className="" xl={4} lg={4} md={6} sm={6} xs={6}>
                                <div className="max-container">
                                    <Col className="img-leadership julia" xl={12} lg={12} md={12} sm={12} xs={12}></Col>
                                    <Col className="text-leader-container" xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <div className="name-leader">Dr. Luc Julia</div>
                                        <div className="title-leader">{t("Board Member")}</div>
                                        <a className="leader-linkedin" href="http://linkedin.com/in/lucjulia" target="_blank" rel="noreferrer"><span aria-label="linkedin"></span></a>
                                        <div className={(switchTooggledJulia ? "text-leader-full" : "text-leader")}>
                                            {t("Dr. Luc Julia, Chief Scientific Officer for Renault")}</div>
                                        <span className="readmore-leader" onClick={ToggleSwitchJulia} aria-hidden="true"> {t("Read")} {(switchTooggledJulia ? t("less") : t("more"))}</span>
                                    </Col>

                                </div>

                            </Col>
                            <Col className="" xl={6} lg={6} md={6} sm={6} xs={6}>
                                <div className="max-container">
                                    <Col className="img-leadership rosalba" xl={12} lg={12} md={12} sm={12} xs={12}></Col>
                                    <Col className="text-leader-container" xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <div className="name-leader">Rosalba Reynoso</div>
                                        <div className="title-leader">CEO</div>
                                        <a className="leader-linkedin" href="https://www.linkedin.com/in/rosalba-reynoso-a1969896/" target="_blank" rel="noreferrer"><span aria-label="linkedin"></span></a>
                                        <div className={(switchTooggledRosalba ? "text-leader-full" : "text-leader")}>{t("Rosalba has over 20 years of experience in managing companies in the service industry")}</div>
                                        <span className="readmore-leader" onClick={ToggleSwitchRosalba} aria-hidden="true"> {t("Read")} {(switchTooggledRosalba ? t("less") : t("more"))}</span>
                                    </Col>
                                </div>
                            </Col>

                            <Col className="" xl={6} lg={6} md={6} sm={6} xs={6}>
                                <div className="max-container">
                                    <Col className="img-leadership remi" xl={12} lg={12} md={12} sm={12} xs={12}></Col>
                                    <Col className="text-leader-container" xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <div className="name-leader">Remi Vespa</div>
                                        <div className="title-leader">{t("President")}</div>
                                        <a className="leader-linkedin" href="https://www.linkedin.com/in/remivespa/" target="_blank" rel="noreferrer"><span aria-label="linkedin"></span></a>
                                        <div className={(switchTooggledRemi ? "text-leader-full" : "text-leader")}> {t("A veteran of the software industry, Remi has gained his experience in the main geographies")} </div>
                                        <span className="readmore-leader" onClick={ToggleSwitchRemi} aria-hidden="true"> {t("Read")} {(switchTooggledRemi ? t("less") : t("more"))}</span>
                                    </Col>
                                </div>
                            </Col>
                        </Row>
                    </Row>
                </div>
            </Container>
        </section>
    )
}

export default Leadership

